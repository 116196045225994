// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-people-js": () => import("./../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-commercial-matters-js": () => import("./../src/pages/services/commercial-matters.js" /* webpackChunkName: "component---src-pages-services-commercial-matters-js" */),
  "component---src-pages-services-conveyancing-js": () => import("./../src/pages/services/conveyancing.js" /* webpackChunkName: "component---src-pages-services-conveyancing-js" */),
  "component---src-pages-services-family-law-js": () => import("./../src/pages/services/family-law.js" /* webpackChunkName: "component---src-pages-services-family-law-js" */),
  "component---src-pages-services-immigration-law-js": () => import("./../src/pages/services/immigration-law.js" /* webpackChunkName: "component---src-pages-services-immigration-law-js" */),
  "component---src-pages-services-index-js": () => import("./../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-notary-public-js": () => import("./../src/pages/services/notary-public.js" /* webpackChunkName: "component---src-pages-services-notary-public-js" */),
  "component---src-pages-services-wills-probate-js": () => import("./../src/pages/services/wills-probate.js" /* webpackChunkName: "component---src-pages-services-wills-probate-js" */)
}

